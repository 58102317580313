import { OptionsType } from 'cookies-next/src/types';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { CookieKeys } from 'constants/Constants';
import { User } from 'types/interface';

export const setAuthTokenCookies = (
  accessToken: string,
  refreshToken: string,
  options?: OptionsType,
) => {
  setCookie(CookieKeys.ACCESS_TOKEN, accessToken, {
    path: '/',
    ...options,
  });
  setCookie(CookieKeys.REFRESH_TOKEN, refreshToken, {
    path: '/',
    ...options,
  });
};

export const getAuthTokenFromCookies = (options?: OptionsType) => {
  const accessToken = getCookie(CookieKeys.ACCESS_TOKEN, {
    path: '/',
    ...options,
  });
  const refreshToken = getCookie(CookieKeys.REFRESH_TOKEN, {
    path: '/',
    ...options,
  });
  return {
    accessToken: accessToken ? String(accessToken) : null,
    refreshToken: refreshToken ? String(refreshToken) : null,
  };
};

export const setUserCookie = (user: Partial<User>, options?: OptionsType) => {
  setCookie(CookieKeys.USER, JSON.stringify(user), {
    path: '/',
    ...options,
  });
};

export const getUserFromCookie = (options?: OptionsType) => {
  const user = getCookie(CookieKeys.USER, {
    path: '/',
    ...options,
  });
  return user ? JSON.parse(String(user)) : null;
};

export const setTempUserCookie = (
  user: Partial<User>,
  provider: string,
  providerToken: string,
  isNewToRegister: boolean,
  options?: OptionsType,
) => {
  setCookie(CookieKeys.USER_PROFILE_TEMP, JSON.stringify(user), {
    path: '/',
    ...options,
  });
  setCookie(CookieKeys.PROVIDER_TOKEN, providerToken, {
    path: '/',
    ...options,
  });
  setCookie(CookieKeys.PROVIDER, provider, {
    path: '/',
    ...options,
  });
  setCookie(CookieKeys.TEMP_REGISTER, isNewToRegister, {
    path: '/',
    ...options,
  });
};

export const getTempUserFromCookie = (options?: OptionsType) => {
  const tempUser = getCookie(CookieKeys.USER_PROFILE_TEMP, {
    path: '/',
    ...options,
  });
  const providerToken = getCookie(CookieKeys.PROVIDER_TOKEN, {
    path: '/',
    ...options,
  });
  const provider = getCookie(CookieKeys.PROVIDER, {
    path: '/',
    ...options,
  });
  const isNewToRegister = getCookie(CookieKeys.TEMP_REGISTER, {
    path: '/',
    ...options,
  });
  return {
    isNewToRegister: isNewToRegister ? Boolean(isNewToRegister) : false,
    provider: provider ? String(provider) : null,
    providerToken: providerToken ? String(providerToken) : null,
    tempUser: tempUser ? JSON.parse(String(tempUser)) : null,
  };
};

export const clearCookies = (keys: string[], options?: OptionsType) => {
  keys.forEach(key => {
    deleteCookie(key, { path: '/', ...options });
  });
};

export const clearAllAuthCookies = (options?: OptionsType) => {
  clearCookies(
    [
      CookieKeys.ACCESS_TOKEN,
      CookieKeys.REFRESH_TOKEN,
      CookieKeys.USER,
      CookieKeys.USER_PROFILE_TEMP,
      CookieKeys.FIREBASE_ID_TOKEN,
      CookieKeys.PROVIDER_TOKEN,
      CookieKeys.PROVIDER,
      CookieKeys.TEMP_REGISTER,
    ],
    options,
  );
};

export const setAuthTokenLocalStorage = (accessToken: string, refreshToken: string) => {
  localStorage.setItem(CookieKeys.ACCESS_TOKEN, accessToken);
  localStorage.setItem(CookieKeys.REFRESH_TOKEN, refreshToken);
};

export const setUserLocalStorage = (user: Partial<User>) => {
  localStorage.setItem(CookieKeys.USER, JSON.stringify(user));
};

export const getAuthTokenFromLocalStorage = () => {
  const accessToken = localStorage.getItem(CookieKeys.ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(CookieKeys.REFRESH_TOKEN);
  return {
    accessToken: accessToken ? String(accessToken) : null,
    refreshToken: refreshToken ? String(refreshToken) : null,
  };
};

export const getUserFromLocalStorage = () => {
  const user = localStorage.getItem(CookieKeys.USER);
  return user ? JSON.parse(String(user)) : null;
};

export const clearLocalStorage = (keys: string[]) => {
  keys.forEach(key => {
    localStorage.removeItem(key);
  });
};

export const clearAllAuthLocalStorage = () => {
  clearLocalStorage([CookieKeys.ACCESS_TOKEN, CookieKeys.REFRESH_TOKEN, CookieKeys.USER]);
};
